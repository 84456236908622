import type { ContextType, ReactNode } from "react";
import {
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";

import { Await, Link, useFetcher } from "@remix-run/react";
import { defer, json } from "@remix-run/server-runtime";

import {
  invariantResponseError,
  validationError,
} from "~/components/forms/validationErrorResponse.server";

import { BasketModel } from "@commerce/models/basket";
import {
  CheckCircledIcon,
  ChevronRightIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";
import { withZod } from "@remix-validated-form/with-zod";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { isValidationError } from "~/components/forms/validationErrorResponse";
import { SkeletonAwait } from "~/components/suspense/skeleton";
import { Alert, AlertTitle } from "~/components/ui/alert";
import { BagIcon } from "~/components/ui/bag-icon";
import { Button } from "~/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui/sheet";
import { Skeleton } from "~/components/ui/skeleton";
import { Spinner } from "~/components/ui/spinner";
import { H4 } from "~/components/ui/text";
import { useURL } from "~/contexts";
import type { C_ProductInfo } from "~/lib/app.types";
import { cn } from "~/lib/ui";
import { getVariantImage } from "~/shop/utils/image";
import { useFormatPrice } from "~/shop/utils/price";
import { STRIPE_PUBLIC_API_KEY } from "~/stripe/constants.client";
import { fetchStripeCreateCheckout } from "~/stripe/resources.client";

import { getOrderTotal } from "../_._checkout+/utils/totals";
import * as BasketLineItem from "./components/basket-line-item";
import { PriceSummaryPartial } from "./components/price-summary-partial";

const RESOURCE_URL = "/resources/basket";
export { RESOURCE_URL as BASKET_RESOURCE_URL };

export type BagProduct = {
  productId: string;
  [key: string]: string;
};

const BasketSchema = z.discriminatedUnion("action", [
  z.object({
    action: z.literal("add"),
    productId: z.string().min(2),
    color: z.string().optional(),
    size: z.string().optional(),
    "leg-length": z.string().optional(),
    quantity: z.string().transform<number>(v => Number(v)),
  }),
  z.object({
    action: z.literal("remove"),
    itemId: z.string().min(2),
  }),
  z.object({
    action: z.literal("update"),
    itemId: z.string().min(2),
    quantity: z.string().transform<number>(v => Number(v)),
  }),
]);
type Actions = z.infer<typeof BasketSchema>;

export const BasketSchemaValidator = withZod(BasketSchema);

const BasketContext = createContext<
  | {
      basket: BasketModel;
      products: C_ProductInfo[];
      openBasket: () => void;
      ref: React.MutableRefObject<{ open?: () => void }>;
    }
  | undefined
>(undefined);
export const useBasket = (): ContextType<typeof BasketContext> => {
  return useContext(BasketContext);
};
export const BasketProvider = ({ children }: { children: ReactNode }) => {
  const url = useURL();
  const fetcher = useFetcher<typeof loader>();
  useEffect(() => {
    fetcher.load(url(RESOURCE_URL));
  }, []);
  const ref = useRef({});
  const openBasket = useCallback(() => {
    if (
      ref.current &&
      "open" in ref.current &&
      typeof ref.current.open === "function"
    )
      ref.current.open();
  }, []);
  const data = useMemo(() => {
    if (!fetcher.data) return undefined;

    return {
      basket: new BasketModel(fetcher.data.basket!),
      products: fetcher.data.products,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.data]);

  return (
    <BasketContext.Provider
      value={data ? { ...data, openBasket, ref } : undefined}
    >
      {children}
    </BasketContext.Provider>
  );
};

export const loader = async ({ context }: LoaderArgs) => {
  const { shop } = context;

  const basket = await shop.getBasket();
  const products = await shop
    .getProducts(basket?.productItems?.map(item => item.productId!) ?? [])
    .then(data => data.products ?? []);
  return defer({ basket, products });
};

export const action = async ({ request, context: { shop } }: ActionArgs) => {
  const validation = await BasketSchemaValidator.validate(
    await request.clone().formData(),
  );

  /* Handle any validation errros here */
  if (validation.error)
    return validationError(validation.error, validation.submittedData);

  const data = validation.data;
  try {
    switch (data.action) {
      case "add": {
        const productData$ = shop.getProducts([data.productId]);
        const basket = await shop.addItemToBasket({
          productId: data.productId,
          quantity: data.quantity,
        });
        const item = basket.productItems?.find(
          p => p.productId === data.productId,
        );
        invariantResponseError(item, "Product item not found in basket");

        const [productData] = (await productData$).products;
        const productImage = getVariantImage(productData) ?? [];
        const product = {
          name: item.productName,
          price: item.price,
          image: productImage,
          productData,
        };
        return json({
          successfullyAdded: true,
          basket,
          product,
          quantity: data.quantity,
        });
      }
      case "remove": {
        const basket = await shop.removeItemFromBasket(data.itemId);
        return json({ successfullyRemoved: true, basket });
      }
      case "update": {
        const basket = await shop.updateItemInBasket(data.itemId, {
          quantity: data.quantity,
        });
        return json({ successfullyEdited: true, basket });
      }
    }
    throw new Error("Unsuppported action");
  } catch (e) {
    console.error(e);
    return validationError((e as Error).message, validation.submittedData);
  }
};

export const useBasketActions = () => {
  const fetcher = useFetcher<typeof action>();
  const url = useURL();
  const add = useCallback(
    (product: BagProduct, quantity = 1) => {
      fetcher.submit(
        {
          action: "add",
          ...product,
          quantity: quantity,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    [fetcher],
  );

  const remove = useCallback(
    (itemId: string) => {
      fetcher.submit(
        {
          action: "remove",
          itemId,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    [fetcher],
  );
  const update = useCallback(
    (itemId: string, quantity: number) => {
      fetcher.submit(
        {
          action: "update",
          itemId: itemId,
          quantity: quantity,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    [fetcher],
  );
  return { add, update, remove, fetcher };
};

export const BasketIcon = () => {
  const basketData = useBasket();
  const { basket } = basketData ?? {};
  const url = useURL();
  if (!basket)
    return (
      <CartDrawer>
        <Button
          as="a"
          aria-label="Open basket"
          href={url("/basket")}
          variant="linkInline"
          className="relative sm-max:h-12 sm-max:text-lg"
        >
          <BagIcon />
          <Skeleton className="absolute bottom-3.5 left-3.5 h-[20px] w-[20px] rounded-full sm-max:bottom-5 sm-max:left-3.5" />
        </Button>
      </CartDrawer>
    );

  return (
    <CartDrawer>
      <Button
        as="a"
        aria-label="Open basket"
        href={url("/basket")}
        variant="linkInline"
        className="sm-max:h-12 sm-max:text-lg"
      >
        <BagIcon />
        <SkeletonAwait
          resolve={basket}
          className="relative h-[20px] w-[20px] rounded-full"
        >
          {basket => {
            const productQty = basket?.productItems?.reduce(
              (acc, currentValue) => acc + (currentValue?.quantity || 0),
              0,
            );
            return (
              basket.productItems?.length && (
                <div className="absolute bottom-3.5 left-3.5 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-accent-9 text-xs font-semibold text-white sm-max:bottom-5 sm-max:left-3.5">
                  {productQty}
                </div>
              )
            );
          }}
        </SkeletonAwait>
      </Button>
    </CartDrawer>
  );
};
export const StripeLoader = ({ onComplete }: { onComplete: () => void }) => {
  const [session] = useState<ReturnType<typeof fetchStripeCreateCheckout>>(() =>
    fetchStripeCreateCheckout(),
  );

  const [stripePromise] = useState(() =>
    import("@stripe/stripe-js").then(module =>
      module.loadStripe(STRIPE_PUBLIC_API_KEY),
    ),
  );
  return (
    <Suspense
      fallback={
        <div className=" flex h-screen w-full items-center justify-center ">
          <Spinner className="mx-auto my-auto h-8 w-8 opacity-50" />
        </div>
      }
    >
      <Await resolve={session}>
        {session => {
          return (
            <>
              <GlobalErrors data={session} />
              {session &&
                !isValidationError(session) &&
                session.session.client_secret && (
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{
                      clientSecret: session.session.client_secret,
                      onComplete,
                    }}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                )}
            </>
          );
        }}
      </Await>
    </Suspense>
  );
};
export function CartDrawer({ children }: { children: React.ReactNode }) {
  const boundryRef = useRef<HTMLDivElement>(null);
  const { fetcher } = useBasketActions();
  const { basket, ref } = useBasket() ?? {};
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      ref.current.open = () => {
        setOpen(true);
      };
    }
    return () => {
      if (ref?.current) {
        ref.current.open = undefined;
      }
    };
  });
  const url = useURL();
  const formatPrice = useFormatPrice();
  const [isQuickCheckout, setIsQuickCheckout] = useState(false);
  const [isQuickCheckoutCompleted, setIsQuickCheckoutCompleted] =
    useState(false);

  // Quick checkout button hidden for now
  // const startQuickCheckout = useCallback(() => {
  //   setIsQuickCheckout(true);
  // }, []);

  return (
    <Sheet
      open={open}
      onOpenChange={open => {
        if (!open) {
          setIsQuickCheckout(false);
          setIsQuickCheckoutCompleted(false);
          setOpen(false);
          return;
        }
        setOpen(true);
      }}
    >
      <div
        onClick={e => {
          e.preventDefault();
        }}
        onKeyDown={e => {
          e.preventDefault();
        }}
        tabIndex={-1}
        role="button"
      >
        <SheetTrigger>{children}</SheetTrigger>
      </div>
      <SheetContent onCloseAutoFocus={e => e.preventDefault()}>
        {isQuickCheckout && (
          <SheetContent className="w-[1000px] overflow-y-auto md:max-w-none">
            {isQuickCheckoutCompleted && (
              <div className="flex flex-col items-center justify-center">
                <H4 className="text-center">Thank you for your order!</H4>
              </div>
            )}
            {!isQuickCheckoutCompleted && (
              <StripeLoader
                onComplete={() => {
                  setIsQuickCheckoutCompleted(true);
                }}
              />
            )}
          </SheetContent>
        )}

        <GlobalErrors data={fetcher.data} />
        <div className="flow-root">
          <Suspense
            fallback={
              <div className="flex h-screen w-full items-center justify-center">
                <Spinner className="h-8 w-8 opacity-50" />
              </div>
            }
          >
            <Await resolve={basket}>
              {basket => {
                const productQty = basket?.productItems?.reduce(
                  (acc, currentValue) => acc + (currentValue?.quantity || 0),
                  0,
                );
                return (
                  <>
                    {basket?.productItems && (
                      <div className="px-5 pt-3">
                        <div className="w-full flex-row items-start justify-between border-b border-b-gray-200">
                          <Alert variant="info">
                            <InfoCircledIcon className="text-dark-on-light h-4 w-4" />
                            <AlertTitle className="text-dark-on-light text-sm font-normal">
                              <p>Spend a further £5 to qualify for:</p>
                              <p>FREE Delivery</p>
                            </AlertTitle>
                          </Alert>

                          <Alert className="mt-3" variant="success">
                            <CheckCircledIcon className="text-dark-on-light h-4 w-4" />
                            <AlertTitle className="text-dark-on-light text-sm font-normal">
                              <p>Your order qualifies for:</p>
                              <p>FREE Click & Collect</p>
                            </AlertTitle>
                          </Alert>

                          <SheetHeader>
                            <SheetTitle className="text-dark-on-light flex py-3">
                              <span className="flex-1 text-2xl">
                                Shopping Bag ({productQty})
                              </span>
                              <span className="self-end text-lg font-normal">
                                {formatPrice(getOrderTotal(basket))}
                              </span>
                            </SheetTitle>
                          </SheetHeader>
                        </div>
                      </div>
                    )}

                    {(!basket?.productItems ||
                      basket?.productItems.length <= 0) && (
                      <div className="px-5 pt-3">
                        <div className="w-full flex-row items-start justify-between border-b border-b-gray-200 py-3">
                          <Alert className="mb-3" variant="info">
                            <InfoCircledIcon className="h-4 w-4" />
                            <AlertTitle className="text-sm font-normal">
                              <p>Spend a further £5 to qualify for:</p>
                              <p>FREE Delivery</p>
                            </AlertTitle>
                          </Alert>
                          <span className="flex-1 text-2xl">
                            Shopping Bag ({basket?.productItems?.length || 0})
                          </span>
                        </div>
                        <Alert className="mt-3" variant="neutral">
                          <InfoCircledIcon className="h-4 w-4" />
                          <AlertTitle className="text-sm font-normal">
                            Empty
                          </AlertTitle>
                        </Alert>
                        <Button
                          as={Link}
                          to={url("/")}
                          onClick={() => setOpen(false)}
                          prefetch="intent"
                          variant="outline"
                          className="my-3 w-full text-base text-neutral-11 hover:no-underline"
                        >
                          Keep Shopping
                        </Button>
                      </div>
                    )}

                    <ul className="flex h-screen scroll-mb-72 flex-col overflow-x-auto px-5 pb-[490px]">
                      {(basket?.productItems ?? [])
                        .concat()
                        .reverse()
                        .map(lineItem => (
                          <BasketLineItem.Root
                            boundryRef={boundryRef}
                            key={lineItem.itemId}
                            lineItem={lineItem}
                            closeCartDrawer={() => setOpen(false)}
                          >
                            {isStale => (
                              <li
                                key={lineItem.itemId}
                                className={cn(
                                  "flex flex-row gap-4 pt-4",
                                  isStale ? "opacity-50" : "opacity-100",
                                )}
                              >
                                <BasketLineItem.ProductImage />
                                <div className="flex-1">
                                  <BasketLineItem.ProductName />
                                  <BasketLineItem.ProductPrice />
                                  <BasketLineItem.ProductVariants />
                                  <div className="flex flex-col text-sm">
                                    <BasketLineItem.ProductQuantity />
                                    <BasketLineItem.RemoveProduct />
                                  </div>
                                </div>
                              </li>
                            )}
                          </BasketLineItem.Root>
                        ))}
                    </ul>
                    {basket?.productItems &&
                      basket?.productItems.length > 0 && (
                        <div className="absolute bottom-0 w-full bg-contrast pb-4 ">
                          <Suspense>
                            <Await resolve={basket}>
                              {basket => (
                                <div className="px-5">
                                  <PriceSummaryPartial summaryData={basket} />
                                </div>
                              )}
                            </Await>
                          </Suspense>

                          {/* Quick checkout button hidden for now*/}
                          {/* <section
                            aria-labelledby="cart-action"
                            className="flex items-center justify-center"
                          >
                            <Button
                              className="col-span-2 mx-auto my-2 w-1/2"
                              onClick={() => startQuickCheckout()}
                            >
                              Quick checkout
                            </Button>
                          </section> */}

                          <section
                            aria-labelledby="cart-action"
                            className="flex items-center justify-center"
                          >
                            <Button
                              as={Link}
                              prefetch="intent"
                              to={url("/checkout")}
                              className="mx-5 mt-3 w-full hover:no-underline"
                            >
                              <span className="px-2 text-base font-semibold text-neutral-1">
                                Go to Checkout
                              </span>
                              <ChevronRightIcon className="h-[18px] w-[18px]" />
                            </Button>
                          </section>
                        </div>
                      )}
                  </>
                );
              }}
            </Await>
          </Suspense>
        </div>
      </SheetContent>
    </Sheet>
  );
}
