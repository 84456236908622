import {
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Await, Link, useMatches } from "@remix-run/react";

import { Cross1Icon, MinusIcon, PlusIcon } from "@radix-ui/react-icons";

import { SkeletonAwait } from "~/components/suspense/skeleton";
import { TriButton } from "~/components/ui/tri-button";
import { useURL } from "~/contexts";
import type {
  C_ProductInfo,
  ProductItem,
  VariationGroup,
} from "~/lib/app.types";
import { useOnCompleted } from "~/lib/remix/fetcher";
import { cn } from "~/lib/ui";
import { toProductUrl, toVariantURL } from "~/lib/utils/url";
import { useFormatPrice } from "~/shop/utils/price";

import { useBasket, useBasketActions } from "../basket";
import { ProductListingImage } from "./product-listings-image";

const Context = createContext<{
  handle: any;
  boundryRef: React.RefObject<HTMLElement>;
  lineItem: ProductItem;
  closeCartDrawer: () => void;
  isStale: boolean;
  setIsStale: (isStale: boolean) => void;
}>(null as any);

export const useBasketLineItem = () => {
  const ctx = useContext(Context);
  if (!ctx)
    throw new Error(
      "useBasketLineItem must be used inside a BasketLineItemProvider",
    );
  return ctx;
};

export const Root = ({
  children,
  boundryRef,
  lineItem,
  closeCartDrawer,
  productItems,
}: {
  children: React.ReactNode | ((isStale: boolean) => React.ReactNode);
  boundryRef: React.RefObject<HTMLElement>;
  lineItem: ProductItem;
  closeCartDrawer: () => void;
  productItems?: C_ProductInfo[];
}) => {
  const handle = useBasketHandle();
  const basket = useBasket();
  const products = productItems || basket?.products || {};
  const itemId = lineItem.itemId;
  const { fetcher } = useBasketActions();
  const [isStale, setIsStale] = useState(false);
  useEffect(() => {
    if (fetcher.formData?.get("action") === "remove") {
      setIsStale(true);
    }
  }, [fetcher, itemId, lineItem.quantity, setIsStale]);

  if (!itemId) return null;
  if (!products) return null;
  return (
    <Context.Provider
      value={{
        handle,
        boundryRef,
        lineItem,
        closeCartDrawer,
        isStale,
        setIsStale,
      }}
    >
      {typeof children === "function" ? children(isStale) : children}
    </Context.Provider>
  );
};
Root.displayName = "BasketLineItem.Root";

export const ProductImage = ({
  className,
  isSmall,
  productItems,
}: {
  className?: string;
  isSmall?: boolean;
  productItems?: C_ProductInfo[];
}) => {
  const basket = useBasket();
  const products = productItems || basket?.products || [];
  const { lineItem, closeCartDrawer } = useBasketLineItem();
  const url = useURL();
  const getVariantURL = useCallback(
    (variationGroup: VariationGroup | undefined, product: { id?: string }) => {
      if (!variationGroup) return url(toProductUrl(product));
      return url(toVariantURL(variationGroup));
    },
    [url],
  );
  if (!products) return null;

  return (
    <SkeletonAwait
      resolve={products}
      className={cn("h-24 w-24 rounded-md", className)}
    >
      {products => {
        const productDetails = products.find(p => p.id === lineItem.productId);
        const productVariationGroup = productDetails?.variationGroups?.find(
          group => group.productId === productDetails["c_variation-group-id"],
        );
        if (!productDetails) return null;
        return (
          <Link
            prefetch="intent"
            className="text-dark-on-light text-sm font-semibold"
            to={getVariantURL(productVariationGroup, productDetails)}
            onClick={() => closeCartDrawer()}
          >
            <ProductListingImage
              product={productDetails}
              isSmall={isSmall}
              className={className}
            />
          </Link>
        );
      }}
    </SkeletonAwait>
  );
};
ProductImage.displayName = "BasketLineItem.ProductImage";

export const ProductName = ({
  className,
  productItems,
}: {
  className?: string;
  productItems?: C_ProductInfo[];
}) => {
  const basket = useBasket();
  const products = productItems || basket?.products || [];
  const { lineItem, closeCartDrawer } = useBasketLineItem();
  const url = useURL();
  const getVariantURL = useCallback(
    (variationGroup: VariationGroup | undefined, product: { id?: string }) => {
      if (!variationGroup) return url(toProductUrl(product));
      return url(toVariantURL(variationGroup));
    },
    [url],
  );
  if (!products) return null;

  return (
    <h3 className={cn(className)}>
      <Suspense
        fallback={
          <a href="/#" onClick={e => e.preventDefault()}>
            {lineItem.productName}
          </a>
        }
      >
        <Await resolve={products}>
          {products => {
            const productDetails = products.find(
              p => p.id === lineItem.productId,
            );
            const productVariationGroup = productDetails?.variationGroups?.find(
              group =>
                group.productId === productDetails["c_variation-group-id"],
            );
            if (!productDetails) return null;

            return (
              <Link
                prefetch="intent"
                className="text-sm font-semibold text-contrast-black"
                to={getVariantURL(productVariationGroup, productDetails)}
                onClick={() => closeCartDrawer()}
              >
                {productDetails?.name}
              </Link>
            );
          }}
        </Await>
      </Suspense>
    </h3>
  );
};
ProductName.displayName = "BasketLineItem.ProductName";

export const ProductPrice = ({ className }: { className?: string }) => {
  const formatPrice = useFormatPrice();
  const { lineItem } = useBasketLineItem();
  const standardPrice =
    (lineItem.c_standardPrice as number) * (lineItem.quantity as number);

  let currentPrice = lineItem.price;

  let salePrice =
    lineItem.taxBasis && lineItem.taxBasis < (lineItem.price || 0)
      ? lineItem.taxBasis
      : undefined;

  if (
    !salePrice &&
    lineItem.price &&
    standardPrice &&
    lineItem.price < standardPrice
  ) {
    currentPrice = standardPrice;
  }

  if (
    !salePrice &&
    lineItem.price &&
    standardPrice &&
    standardPrice > lineItem.price
  ) {
    salePrice = lineItem.price;
  }

  return (
    <p
      className={cn("py-1.5 text-xs font-light text-contrast-black", className)}
    >
      {!salePrice && formatPrice(currentPrice)}
      {salePrice && (
        <>
          <span className="ml-auto mr-0 line-through">
            {formatPrice(currentPrice)}
          </span>
          <span className="pl-1 pt-2 text-salePrice">
            {formatPrice(salePrice)}
          </span>
        </>
      )}
    </p>
  );
};
ProductPrice.displayName = "BasketLineItem.ProductPrice";

export const ProductVariants = () => {
  const { lineItem } = useBasketLineItem();
  const { products } = useBasket() ?? {};
  if (!products) return null;
  return (
    <Await resolve={products}>
      {products => {
        const productDetails = products?.find(p => p.id === lineItem.productId);
        const size = productDetails?.variationValues?.size as
          | string
          | undefined;
        const color = productDetails?.variationValues?.color as
          | string
          | undefined;
        return (
          <div>
            <p className="py-1.5 text-xs font-light text-contrast-black">
              <span className="font-normal">Color: </span>
              {color}
            </p>
            {size !== "onesize" && (
              <p className="pb-1.5 text-xs font-light text-contrast-black">
                <span className="font-normal">Size: </span>
                {size}
              </p>
            )}
          </div>
        );
      }}
    </Await>
  );
};
ProductVariants.displayName = "BasketLineItem.ProductVariants";

export const RemoveProduct = ({ className }: { className?: string }) => {
  const { lineItem, isStale, setIsStale } = useBasketLineItem();
  const { remove, fetcher } = useBasketActions();
  const itemId = lineItem?.itemId;

  useEffect(() => {
    if (fetcher.formData?.get("action") === "remove") {
      setIsStale(true);
    }
  }, [fetcher, itemId, lineItem.quantity, setIsStale]);

  if (!itemId) return null;

  return (
    <TriButton
      variant="link"
      className={cn("self-end hover:no-underline", className)}
      isLoading={isStale}
      onClick={() => remove(itemId)}
    >
      <Cross1Icon className="inline h-4 w-4 text-remove" />
      <span className="px-2 text-sm font-light text-remove hover:underline">
        Remove
      </span>
    </TriButton>
  );
};
RemoveProduct.displayName = "BasketLineItem.RemoveProduct";

export const ProductQuantity = ({ className }: { className?: string }) => {
  const { lineItem } = useBasketLineItem();
  const itemId = lineItem?.itemId;
  const { update, fetcher } = useBasketActions();
  const [isUpdating, setIsUpdating] = useState<false | "up" | "down">(false);

  useEffect(() => {
    if (fetcher.formData?.get("itemId") === itemId) {
      setIsUpdating(
        Number(fetcher.formData?.get("quantity")) > lineItem.quantity!
          ? "up"
          : "down",
      );
    }
  }, [fetcher, itemId, lineItem.quantity]);

  useOnCompleted((data, payload) => {
    if (payload?.formData?.get("itemId") === itemId) setIsUpdating(false);
  }, fetcher);

  if (!itemId) return null;

  return (
    <div
      className={cn(
        "b-1 relative flex w-32 items-center py-3 text-center align-middle text-gray-500",
        className,
      )}
    >
      <TriButton
        variant="secondary"
        className="h-6 w-6 rounded-full border border-gray-200 bg-white p-0 [&>*]:mr-0"
        doneContent={<MinusIcon />}
        loadingContent=""
        doneContentTimeout={0}
        isLoading={isUpdating === "down"}
        onClick={() =>
          update(itemId, lineItem.quantity ? lineItem.quantity - 1 : 0)
        }
      >
        <MinusIcon />
      </TriButton>
      <span className="px-2 text-xs font-semibold text-contrast-black">
        Qty: <span className="pl-2">{lineItem.quantity}</span>
      </span>
      <TriButton
        variant="secondary"
        className="h-6 w-6 rounded-full border border-gray-200 bg-white p-0 [&>*]:mr-0"
        doneContent={<PlusIcon />}
        loadingContent=""
        doneContentTimeout={0}
        isLoading={isUpdating === "up"}
        onClick={() =>
          update(itemId, lineItem.quantity ? lineItem.quantity + 1 : 1)
        }
      >
        <PlusIcon />
      </TriButton>
    </div>
  );
};
ProductQuantity.displayName = "BasketLineItem.ProductQuantity";

const useBasketHandle = () => {
  const matches = useMatches();
  const match = useMemo(
    () =>
      matches
        .reverse()
        .filter(match => (match as any).handle?.["basketLineItem"])
        .pop(),
    [matches],
  );
  const handle = (match as any)?.handle?.["basketLineItem"];
  if (typeof handle === "function") {
    return handle((match as any).data);
  }
  return handle;
};
