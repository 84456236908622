import { cn } from "~/lib/ui";

export const BagIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("inline", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6911 7.25H21C21.2205 7.25 21.4298 7.347 21.5723 7.51522C21.7148 7.68344 21.776 7.90583 21.7398 8.1233L19.7398 20.1233C19.6795 20.4849 19.3666 20.75 19 20.75H5.00001C4.63338 20.75 4.32048 20.4849 4.26021 20.1233L2.26021 8.1233C2.22397 7.90583 2.28524 7.68344 2.42774 7.51522C2.57024 7.347 2.77954 7.25 3.00001 7.25H7.30888C7.66846 4.98301 9.63185 3.25 12 3.25C14.3682 3.25 16.3315 4.98301 16.6911 7.25ZM3.88535 8.75L5.63535 19.25H18.3647L20.1147 8.75H3.88535ZM8.83697 7.25C9.17555 5.81665 10.4632 4.75 12 4.75C13.5368 4.75 14.8245 5.81665 15.163 7.25H8.83697Z"
        fill="currentColor"
      />
    </svg>
  );
};
